import { useEffect, useState } from 'react'
import MainTitle from '../../../../components/template/MainTitle'
import SubTitle from '../../../../components/template/SubTitle'
import { classNames } from '../../../../components/utils/Utils'
import { Tooltip } from 'react-tooltip'
import api from '../../../../components/utils/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons'

const ConfusionMatrix = ({ confusionMatrix }) => (
    <div className='border my-1 p-1 rounded'>
        {confusionMatrix.map((row, i) => <div key={i} style={{ display: 'grid', gridTemplateColumns: `repeat(${row.length}, minmax(0, 1fr))` }}>
            {row.map((e, i2) => <div
                data-tooltip-id="confusion-matrix-tooltip"
                data-tooltip-html={i === 0 || i2 === 0 ? e : `<div className='text-left'>Value: ${e}<br/>Target: ${row[0]}<br/>Prediction: ${confusionMatrix[0][i2]}</div>`}
                data-tooltip-place="left"
                key={i2} className={classNames(
                    'truncate py-2 px-1 text-sm first:font-semibold',
                    e > 0.25 ? e > 0.5 ? e > 0.75 ? i === i2 ? "bg-blue-600 text-blue-100" : "bg-red-600 text-red-100" : i === i2 ? "bg-blue-300 text-blue-800" : "bg-red-300 text-red-800" : i === i2 ? "bg-blue-100 text-blue-800" : "bg-red-100 text-red-800" : "",
                    i === 0 ? "font-semibold" : "",
                )}>{e}</div>)}
        </div>)}
        <Tooltip id="confusion-matrix-tooltip" />
    </div >
)

const EvaluationModal = ({ resultData, mergeRuleId }) => {
    const [confusionMatrix, setConfusionMatrix] = useState([])
    const [isAdvancedVisible, setIsAdvancedVisible] = useState(false)

    useEffect(() => {
        if (resultData && mergeRuleId) {
            api.get(`/api/post-action/merge-rules/${mergeRuleId}/evaluations/${resultData.evaluation_id}/confusion-matrix`)
                .then(res => setConfusionMatrix(res.data.confusion_matrix))
                .catch(err => console.error(err))
        }
    }, [resultData, mergeRuleId])
    return (
        <div>
            <MainTitle title={"Evalaution Results"} />
            <div className='my-4'>
                <SubTitle title={"Detail"} />
                <div className='md:grid md:grid-cols-3 gap-3'>
                    {[
                        { label: "Date", value: resultData.created_at },
                        { label: "Threshold", value: resultData.threshold.name },
                        { label: "Target Testset", value: resultData.testset },
                    ].map((e, i) => <div key={i}>
                        <div className='text-sm font-semibold text-left m-1'>{e.label}</div>
                        <div className="w-full block bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight">{e.value}</div>
                    </div>)}
                </div>
                <div className='text-left text-sm text-gray-500 m-3'>
                    <button type='button' onClick={() => setIsAdvancedVisible(!isAdvancedVisible)}>Advanced Option <FontAwesomeIcon icon={isAdvancedVisible ? faAngleDown : faAngleRight} /></button>
                </div>
                {isAdvancedVisible && <div className='md:grid md:grid-cols-4 gap-3'>
                    {[
                        { label: "Use Prepared Vector", value: resultData.use_numpy ? "Yes" : "No" },
                        { label: "Minimun Quantity of Testset", value: resultData.minimum_testset_quantity },
                        { label: "Minimun Threshold", value: resultData.minimum_threshold },
                        { label: "Maximum Threshold", value: resultData.maximum_threshold },
                    ].map((e, i) => <div key={i}>
                        <div className='text-sm font-semibold text-left m-1'>{e.label}</div>
                        <div className="w-full block bg-white text-gray-700 border border-gray-300 rounded py-3 px-4 leading-tight">{e.value}</div>
                    </div>)}
                </div>}
            </div>
            <div className='my-4'>
                <SubTitle title={"Summary"} />
                <div className='shadow grid grid-cols-5 rounded my-2'>
                    {["F-score", "Precision", "Recall", "AP", "Testset Quantity"].map((e, i) => <div key={i} className="p-2 text-sm bg-gray-100 text-gray-700">{e}</div>)}
                    {[
                        resultData.threshold_evaluation_summary.f_score,
                        resultData.threshold_evaluation_summary.precision,
                        resultData.threshold_evaluation_summary.recall,
                        resultData.threshold_evaluation_summary.ap,
                        resultData.threshold_evaluation_summary.testset_quantity
                    ].map((e, i) => <div key={i} className='p-3'>{e}</div>)}
                </div>
            </div>
            <div className='my-4'>
                <SubTitle title={"Results by Class"} />
                <div className='text-sm shadow rounded my-2'>
                    <div className='grid grid-cols-8 bg-gray-100 text-gray-700 items-center'>
                        {["Tag", "F-score", "Precision", "Recall", "AP", "Testset Quantity", "Optimized Threshold"].map((e, i) => <div key={i} className={classNames('p-2', i === 0 ? "col-span-2" : "")}>{e}</div>)}
                    </div>
                    {resultData.threshold_evaluation_values.map((e, i) => <div key={i} className='grid grid-cols-8 py-3 border-b border-gray-200'>
                        <div className='font-semibold col-span-2 truncate'>{e.merge_rule_tags.name}</div>
                        <div>{e.f_score}</div>
                        <div>{e.precision}</div>
                        <div>{e.recall}</div>
                        <div>{e.ap}</div>
                        <div>{e.testset_quantity}</div>
                        <div>{e.optimized_threshold}</div>
                    </div>)}
                </div>
            </div>
            <div className='my-4'>
                <SubTitle title={"Confusion Matrix"} />
                {confusionMatrix.length === 0 ? <p className='text-left border rounded p-3 text-gray-500 my-2'>A confusion matrix is not available for this merge rule.</p> : <ConfusionMatrix confusionMatrix={confusionMatrix} />}
            </div>
        </div>
    )
}

export default EvaluationModal