import { useMemo } from 'react'
import Select from 'react-select'

const EvaluationTestsetSelector = ({ testset, setTestset }) => {
    const options = useMemo(() => [
        { label: 'Internal', value: 'internal' },
        { label: 'ESC 50', value: 'esc50' },
        { label: 'DCASE 2018 - Task 2', value: 'dcase2018_task2' },
    ], [])
    return (
        <Select
            options={options}
            value={testset}
            onChange={(e) => { setTestset(undefined); setTestset(e) }}
            placeholder="Select a testset"
        />
    )
}

export default EvaluationTestsetSelector