import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import MainTitle from "../../components/template/MainTitle"
import ButtonWrap from "../../components/template/ButtonWrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons"
import DBGenerationStatusPill from "./DBGenerationStatusPill"
import api from "../../components/utils/api"
import TraceStepper from "../../components/template/TraceStepper"

const DBGenarationDetail = () => {
    const [cochlDB, setCochlDB] = useState({})
    const [traceCode, setTraceCode] = useState(101)
    const params = useParams()
    const navigate = useNavigate();

    const fetchTrace = useCallback(() => {
        api.get(`/api/db-generation/cochl-dbs/${params.id}/traces`)
            .then(res => setTraceCode(res.data.cochl_db_traces.length > 0 ? res.data.cochl_db_traces[0].trace_code : 100))
            .catch(error => console.error(error))
    }, [params])

    useEffect(() => {
        api.get(`/api/db-generation/cochl-dbs/${params.id}`)
            .then(res => setCochlDB(res.data.cochl_db))
            .catch(error => console.error(error))
        fetchTrace()
    }, [params, fetchTrace])


    const defaultSteps = useMemo(() => [
        {
            id: 0, title: 'CochlDB generation', stepStatus: 'waiting', subSteps: [
                { id: 1, message: 'CochlDB generation started', subStepStatus: 'waiting' },
                { id: 2, message: 'Create new Cochlogy', subStepStatus: 'waiting' },
                { id: 3, message: 'Verify data split', subStepStatus: 'waiting' },
                { id: 4, message: 'Get raw data', subStepStatus: 'waiting' },
                { id: 5, message: 'Create new CochlDB', subStepStatus: 'waiting' },
                { id: 6, message: 'Create new class filter and benchmark merge rule', subStepStatus: 'waiting' },
                { id: 9, message: 'CochlDB generation completed', subStepStatus: 'waiting' },
            ]
        },
        {
            id: 1, title: 'Model training', stepStatus: 'waiting', subSteps: [
                { id: 1, message: 'Model training started', subStepStatus: 'waiting' },
                { id: 2, message: 'Prepare model training', subStepStatus: 'waiting' },
                { id: 3, message: 'Download data', subStepStatus: 'waiting' },
                { id: 4, message: 'Train model', subStepStatus: 'waiting' },
                { id: 5, message: 'Upload model performance score', subStepStatus: 'waiting' },
                { id: 9, message: 'Model training completed', subStepStatus: 'waiting' },
            ]
        },
        {
            id: 2, title: 'CochlDB histogram analysis', stepStatus: 'waiting', subSteps: [
                { id: 1, message: 'Analysis started', subStepStatus: 'waiting' },
                { id: 2, message: 'Download resources', subStepStatus: 'waiting' },
                { id: 3, message: 'Analyze train data', subStepStatus: 'waiting' },
                { id: 4, message: 'Analyze valid data', subStepStatus: 'waiting' },
                { id: 5, message: 'Analyze internal test data', subStepStatus: 'waiting' },
                { id: 6, message: 'Analyze external test data', subStepStatus: 'waiting' },
                { id: 7, message: 'Calculate score', subStepStatus: 'waiting' },
                { id: 9, message: 'Analysis completed', subStepStatus: 'waiting' },
            ]
        },
    ], [])

    return (
        <div className='default-background'>
            {cochlDB.hasOwnProperty('db_ver') && <div>
                <div className="flex justify-between items-center my-5">
                    <div className="flex items-center">
                        <MainTitle title={cochlDB.db_ver} />
                        <div className="mx-3"><DBGenerationStatusPill value={cochlDB.status.name} /></div>
                    </div>
                    <div className="grid grid-cols-2 gap-2 items-center">
                        {cochlDB.status.name !== 'GENERATED' && <ButtonWrap contents={<FontAwesomeIcon icon={faArrowRotateRight} />} onClick={() => fetchTrace()} />}
                        <ButtonWrap contents={<FontAwesomeIcon icon={faArrowLeft} />} onClick={() => navigate('/db-generation')} />
                    </div>
                </div>
                <TraceStepper defaultSteps={defaultSteps} traceCode={cochlDB.status.value === 10 ? 109 : traceCode} />
            </div>}
        </div>
    )
}

export default DBGenarationDetail