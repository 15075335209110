import { useEffect, useState } from "react"
import NewInfoFormat from "../../components/template/NewInfoFormat"
import Select from "react-select"
import api from "../../components/utils/api"
import EvaluationComparisonSummary from "./EvaluationComparisonSummary"
import EvaluationComparisonInternalResult from "./EvaluationComparisonInternalResult"

const EvaluaitonComparisonSelector = ({ idx, setValue, models, testset }) => {
    const [model, setModel] = useState()
    const [mergeRules, setMergeRules] = useState([])
    const [mergeRule, setMergeRule] = useState()

    useEffect(() => {
        if (testset) { setModel(null) }
    }, [testset])

    useEffect(() => {
        if (model && model.hasOwnProperty('value')) {
            setMergeRules([])
            setMergeRule(null)
            api.get(`/api/evaluation-comparison/evaluations/${testset.value}/general_models/${model.value}/valid-merge-rules`)
                .then(res => setMergeRules(res.data.valid_merge_rules.map(e => ({ label: e.name, value: e.id }))))
                .catch(e => console.error(e))
        }
    }, [model, testset])

    useEffect(() => {
        if (mergeRule && mergeRule.hasOwnProperty('value')) {
            api.get(`/api/post-action/merge-rules/${mergeRule.value}/evaluations?general_model_id=${model.value}`)
                .then(res => setValue(res.data.evaluations[0]))
                .catch(err => console.error(err))
        }
    }, [mergeRule, model, setValue])

    return (
        <div>
            <NewInfoFormat label={`Model ${idx}`} contents={
                <Select
                    options={models}
                    value={model}
                    onChange={e => setModel(e)}
                />
            } />
            <NewInfoFormat label={`Merge Rule ${idx}`} contents={
                <Select
                    options={mergeRules}
                    value={mergeRule}
                    onChange={e => setMergeRule(e)}
                />
            } />
        </div>
    )
}

const EvaluaitonComparisonColumns = ({ testset }) => {
    const [models, setModels] = useState()
    const [evaluation1, setEvaluation1] = useState()
    const [evaluation2, setEvaluation2] = useState()
    useEffect(() => {
        setModels(undefined)
        setEvaluation1(undefined)
        setEvaluation2(undefined)
        if (testset.hasOwnProperty('value')) {
            api.get(`/api/evaluation-comparison/evaluations/${testset.value}/general-models`)
                .then(res => setModels(res.data.general_models.map(e => ({ label: e.name, value: e.id, experiment_id: e.experiment_id }))))
                .catch(e => console.error(e))
        }
    }, [testset])
    return (
        <div>
            <div className="grid grid-cols-2 gap-2">
                {[
                    { setValue: setEvaluation1 },
                    { setValue: setEvaluation2 },
                ].map((e, i) => <EvaluaitonComparisonSelector idx={i + 1} setValue={e.setValue} models={models} testset={testset} key={i} />)}
            </div>
            <EvaluationComparisonSummary
                evaluation1={evaluation1}
                evaluation2={evaluation2}
            />
            <EvaluationComparisonInternalResult
                evaluation1={evaluation1}
                evaluation2={evaluation2}
            />
        </div>
    )
}

export default EvaluaitonComparisonColumns