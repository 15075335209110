import { useEffect, useRef, useState } from 'react';
import SubTitle from '../../components/template/SubTitle'
import Select from 'react-select'
import ButtonFull from '../../components/template/ButtonFull'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import ButtonWrap from '../../components/template/ButtonWrap'
import MainTitle from '../../components/template/MainTitle'
import { Link, useNavigate } from 'react-router-dom'
import LoadExistingMergeRule from './components/LoadExistingMergeRule'
import api from '../../components/utils/api';
import LoadBenchmarkTemplate from './components/LoadBenchmarkTemplate';

const MergeRuleOptionGenerator = ({ mergeRulePairs, setMergeRulePairs, options, index }) => (
    <div className='grid grid-cols-4 my-2 gap-3'>
        <input
            type="text"
            value={mergeRulePairs[mergeRulePairs.findIndex(pair => pair.id === index)].tagName}
            onChange={(e) => {
                // check if the tag name follows the name rule
                const validation = (text) => /[^a-zA-Z|^_]/i.test(text);
                const capitalText = (text) => text[0].toUpperCase() + text.substring(1).toLowerCase()
                if (validation(e.target.value)) {
                    alert("Please enter English characters and underbars only.")
                    return;
                }
                const newTagName = e.target.value ? capitalText(e.target.value) : e.target.value
                // check if there is the same class name
                if (newTagName && mergeRulePairs.map(e => e.tagName).includes(newTagName)) {
                    alert("The tag name should be unique.")
                    return;
                }
                let newMergeRulePairs = mergeRulePairs
                newMergeRulePairs[mergeRulePairs.findIndex(pair => pair.id === index)].tagName = newTagName
                setMergeRulePairs([...newMergeRulePairs])
            }}
            className="border border-[#CCCCCC] h-[38px] p-3 rounded w-full"
        />
        <div className='col-span-3 flex'>
            <div className='w-full'>
                <Select
                    options={options.map(opt => ({ label: opt, value: opt }))}
                    value={mergeRulePairs[mergeRulePairs.findIndex(pair => pair.id === index)].mergedClasses.map(opt => ({ label: opt, value: opt }))}
                    onChange={(e) => {
                        const newPairs = mergeRulePairs
                        newPairs[mergeRulePairs.findIndex(pair => pair.id === index)].mergedClasses = e.map(opt => opt.value)
                        setMergeRulePairs([...newPairs])
                    }}
                    isMulti
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, textAlign: 'center' }) }}
                    menuPortalTarget={document.querySelector("body")}
                />
            </div>
            <button
                type="button"
                className="ml-2 w-10 h-10 bg-transparent hover:bg-gray-800 text-gray-700 font-semibold hover:text-white py-2 px-3 border border-gray-300 hover:border-transparent rounded disabled:opacity-25"
                onClick={() => setMergeRulePairs(mergeRulePairs.filter(option => option.id !== index))}
            ><FontAwesomeIcon icon={faX} size="xs" /></button>
        </div>

    </div>
)


const NewMergeRuleModal = ({ generalModel, mergeRules }) => {
    const [mergeRuleName, setMergeRuleName] = useState("")
    const [mergeRulePairs, setMergeRulePairs] = useState([{ id: 0, tagName: "", mergedClasses: [] }])
    const [existMergeRulePairs, setExistMergeRulePairs] = useState([])
    const [options, setOptions] = useState([])
    const nextID = useRef(1);
    const navigate = useNavigate()

    useEffect(() => {
        api.get(`/api/post-action/experiments/${generalModel.generalModels.experiment_id}/event-classes`)
            .then(res => setOptions(res.data.event_classes.map(e => e.name)))
            .catch(err => console.error(err))
    }, [generalModel])

    useEffect(() => {
        if (existMergeRulePairs.length !== 0) {
            setMergeRulePairs(existMergeRulePairs.map(e => ({ id: e.id, tagName: e.name, mergedClasses: e.merged_classes.filter(ele => options.includes(ele)) })))
        }
    }, [options, existMergeRulePairs])

    const onClick = () => {
        api.post(`/api/post-action/experiments/${generalModel.generalModels.experiment_id}/merge-rules`, {
            name: mergeRuleName,
            tags: mergeRulePairs.map(e => ({ id: e.id, tag_name: e.tagName, merged_classes: e.mergedClasses })),
            general_model_id: generalModel.generalModels.id
        }).then(() => navigate(0))
            .catch(err => console.error(err))
    }

    return (
        <div className='h-full grid grid-rows-9'>
            <div className='my-3 flex items-end justify-between'>
                <MainTitle title="New Merge Rule" />
                <div className='flex'>
                    <LoadExistingMergeRule setExistMergeRulePairs={setExistMergeRulePairs} />
                    <LoadBenchmarkTemplate />
                    <Link
                        target='_blank' rel="noopener noreferrer"
                        to={`/all-classes/${generalModel.dbVersion}-${generalModel.expVersion}-${generalModel.modelType}`}
                        className="font-semibold py-2 px-4 border hover:border-transparent rounded disabled:opacity-25 bg-transparent hover:bg-gray-800 text-gray-700 hover:text-white border-gray-300"
                    >View Available Classes</Link>
                </div>
            </div>
            <div>
                <SubTitle title="Merge Rule Name" />
                <input
                    type="text"
                    value={mergeRuleName || ""}
                    onChange={(e) => {
                        // check if the merge rule name follows the name rule
                        const validation = (text) => /[^a-zA-Z0-9|^_-]/i.test(text);
                        if (validation(e.target.value)) {
                            alert("Please enter English characters, numbers, underbars, and hyphens only.")
                            return;
                        }
                        // check if the merge rule name is duplicated
                        if (mergeRules.includes(e.target.value)) {
                            alert("Merge rule name should be unique.")
                            return;
                        }
                        setMergeRuleName(e.target.value)
                    }}
                    className="border border-[#CCCCCC] h-[38px] p-3 rounded w-full my-2"
                />
            </div>
            <div className='grid grid-cols-4'>
                <div><SubTitle title="Tag" /></div>
                <div className='col-span-3'><SubTitle title="Paired Classes" /></div>
            </div>
            <div className='row-span-5 overflow-auto min-h-[300px]'>
                {mergeRulePairs.map((pair, i) => <MergeRuleOptionGenerator
                    mergeRulePairs={mergeRulePairs}
                    setMergeRulePairs={setMergeRulePairs}
                    options={options}
                    index={pair.id}
                    key={i}
                />)}
                <div>
                    <ButtonFull contents="Add New Pair" onClick={() => {
                        setMergeRulePairs([...mergeRulePairs, { id: nextID.current, tagName: "", mergedClasses: [] }])
                        nextID.current += 1
                    }} />
                </div>
            </div>
            <div className='text-right mt-3'>
                <ButtonWrap contents="Create" onClick={onClick} isDisabled={!(mergeRuleName && mergeRulePairs.filter(e => e.tagName && e.mergedClasses.length).length)} />
            </div>
        </div >
    )
}

export default NewMergeRuleModal