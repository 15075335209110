import { useEffect, useState } from "react";
import ButtonWrap from "../../../components/template/ButtonWrap";
import Modal from "../../../components/template/Modal";
import Select from 'react-select';
import MainTitle from "../../../components/template/MainTitle";
import api from "../../../components/utils/api";

const LoadBenchmarkTemplateSelector = ({ value, setValue }) => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        api.get('/api/benchmark-datasets/')
            .then(res => setOptions(res.data.benchmark_datasets.map(e => ({ label: e.name, value: e.id }))))
            .catch(error => console.error(error))
    }, [])

    return (
        <div>
            <MainTitle title={'Load Template'} />
            <Select
                options={options}
                value={value}
                onChange={setValue}
            />
        </div>

    )
}

const LoadBenchmarkTemplate = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const openModal = () => { setModalVisible(true); }
    const closeModal = () => { setModalVisible(false); }
    return (
        <div className="mr-2">
            <ButtonWrap contents={'Load Template'} onClick={openModal} />
            {modalVisible && <Modal
                visible={modalVisible}
                closable={true}
                maskClosable={true}
                onClose={closeModal}>{
                    <div><LoadBenchmarkTemplateSelector /></div>
                }</Modal>}
        </div>
    )
}

export default LoadBenchmarkTemplate